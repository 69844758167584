/**
 * Image Switcher
 *
 * On IMG tag is automatically loaded closest best resolution of image by width his parent.
 * On DIV tag is closest best resolution by width itself.
 * If u need, u can set xYZ multiplier for example for retina display.
 *
 * Possible global options:
 *      - cssClass = selector for images
 *      - multipler = how much u want ready multiplier (x2, x3, etc.)
 *      - breakpoints = which breakpoints u want handle
 *
 * Also, u can set options on image separately by data attributes:
 *      - data-img-switcher-bp = breakpoints
 *      - data-img-switcher-mp = multiplier
 *
 * @author Lukáš Strišovský, 2017 <strisovsky.lukas@gmail.com>
 */
+(function(){
    /**
     * Init Image Switcher
     *
     * @example options = {cssClass = 'selector', multiplier = [1, 2], breakpoints = [320, 1024, 1920]}
     *
     * @param {Object} options
     *  - options.cssClass {String}
     *  - options.multiplier {Array}
     *  - options.breakpoints {Array}
     */
    var imgSwitcher = function(options) {
        // Attributes
        var _breakpointsAttr = 'data-img-switcher-bp',
            _multipliersAttr = 'data-img-switcher-mp',
            _lastBreakpointAttr = 'data-img-switcher-lbp';

        // Options
        var _options = options ? options : {};
        var cssClass = _options.cssClass ? _options.cssClass : 'js-img-switcher',
            multipliers = _options.multiplier ? _options.multiplier : [1],
            breakpoints = _options.breakpoints ? _options.multiplier : [320, 640, 768, 1024, 1280, 1920],
            timer = null;

        // Additional
        var cssStyleClass = 'o-img-switcher';

        /**
         * Get which multiplier we need by DPI of resolution
         *
         * @param {Array} multipliers
         * @returns {number}
         */
        var getMultiplier = function(multipliers) {
            var multiplier = 1;

            if(multipliers.length === 1){
                multiplier = parseInt(multipliers[0], 10);
            }
            else {
                multipliers.sort(function (a, b) {
                    return b - a;
                });
                for (var i = 0; i < multipliers.length; i++) {
                    if (isResMultiplied(multipliers[i])) {
                        multiplier = parseInt(multipliers[i], 10);
                        break;
                    }
                }
            }

            return multiplier;
        };

        /**
         * Check if is resolution multiplied
         *
         * @param {int} pixelRatio
         * @returns {boolean}
         */
        var isResMultiplied = function(pixelRatio) {
            var dpi = pixelRatio * 96;
            pixelRatio = pixelRatio - 0.75;

            return !!((window.matchMedia && (window.matchMedia('only screen and (min-resolution: '+dpi+'dpi), only screen and (min-resolution: 2dppx), only screen and (min-resolution: '+(dpi/2.54)+'dpcm)').matches || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: '+pixelRatio+'), only screen and (-o-min-device-pixel-ratio: '+(pixelRatio * 4)+'/4), only screen and (min--moz-device-pixel-ratio: '+pixelRatio+'), only screen and (min-device-pixel-ratio: '+pixelRatio+')').matches)) || (window.devicePixelRatio && window.devicePixelRatio >= pixelRatio));
        };

        /**
         * Get Closest Resolution
         *
         * @param {int} width
         * @param {int} multiplier
         * @param {Array} breakpoints
         * @returns {int}
         */
        var getClosestValueCeil = function(width, multiplier, breakpoints) {
            var higherMinValue = null,
                lowerMaxValue = null,
                targetValue = null,
                breakpoint = null;

            width = width * multiplier;

            for(var i = 0; i < breakpoints.length; i++){
                breakpoint = parseInt(breakpoints[i], 10);

                if(breakpoint >= width && higherMinValue === null){
                    higherMinValue = breakpoint;
                    break;
                }

                if(breakpoint < width && (lowerMaxValue === null || breakpoint > lowerMaxValue)){
                    lowerMaxValue = breakpoint;
                }

                breakpoint = null;
            }

            if(higherMinValue !== null){
                targetValue = higherMinValue;
            }
            else {
                targetValue = lowerMaxValue;
            }

            return targetValue;
        };

        /**
         * Core of Image Switcher
         *
         */
        var imgSwitch = function(){
            for(var i = 0; i < images.length; i++) {
                var image = images[i],
                    imageWidth = null,
                    imageSrc = null,
                    imageLastBreakpoint = parseInt(image.getAttribute(_lastBreakpointAttr), 10),
                    _multiplier = multiplier,
                    _breakpoints = breakpoints;

                if(image.tagName === 'IMG'){
                    imageWidth = parseInt(image.parentNode.clientWidth, 10);
                    imageSrc = image.getAttribute('src');
                }
                else {
                    imageWidth = parseInt(image.clientWidth, 10);
                    imageSrc = getComputedStyle(image).backgroundImage;
                }

                // Check if we have probably correct src, if not, skip this image
                if(imageSrc === null || (typeof imageSrc === 'string' && imageSrc.trim() === '') || imageSrc === 'none') {
                    continue;
                }

                // Check if we have Attribute for multipliers
                if(image.hasAttribute(_multipliersAttr)){
                    _multiplier = getMultiplier(image.getAttribute(_multipliersAttr).split(','));
                }

                // Check if we have Attribute for breakpoints
                if(image.hasAttribute(_breakpointsAttr)){
                    _breakpoints = image.getAttribute(_breakpointsAttr).split(',');
                }

                // Get target breakpoint/width
                var targetBreakpoint = getClosestValueCeil(imageWidth, _multiplier, _breakpoints);

                // if we have another width as we need, try change it
                if(imageLastBreakpoint !== targetBreakpoint) {
                    // remove old resolution from src and set new
                    var newImageSrc = imageSrc.replace('-' + imageLastBreakpoint, '').replace(/.([^.]+).?$/g, '-' + targetBreakpoint + imageSrc.match(/.([^.]+).?$/g)[0]),
                        newImage = new Image();
                    image.setAttribute(_lastBreakpointAttr, targetBreakpoint);  // save new resolution to attribute

                    if(image.tagName === 'IMG'){
                        newImage.src = newImageSrc;
                        newImage.onload = (function(img, rs){
                            return function () {
                                img.src = rs;
                                img.classList.remove(cssStyleClass);

                                newImage = null;
                            };
                        })(image, newImageSrc);
                    }
                    else {
                        newImageSrc = newImageSrc.replace('url(', '').replace(')', '').replace(/"/g, '').replace(/'/g, '');
                        newImage.src = newImageSrc;
                        newImage.onload = (function (img, rs) {
                            return function() {
                                img.style.backgroundImage = 'url(' + rs + ')';
                                img.classList.remove(cssStyleClass);

                                newImage = null;
                            };
                        })(image, newImageSrc);
                    }

                    newImageSrc = null;
                }

                // reset all variables for memory
                image = null;
                imageWidth = null;
                imageSrc = null;
                imageLastBreakpoint = null;
                _multiplier = null;
                _breakpoints = null;
                targetBreakpoint = null;
            }
        };

        // load all images and get multiplier
        var images = document.getElementsByClassName(cssClass),
            multiplier = getMultiplier(multipliers);

        // run core
        imgSwitch();

        // bind event to windows resize
        window.addEventListener('resize', function() {
            if(timer){
                clearTimeout(timer);
            }
            timer = setTimeout(function(){
                imgSwitch();
            }, 333);
        }, true);
    };

    // Init Image Switcher
    imgSwitcher({
        multiplier: [1, 2]
    });
})();
