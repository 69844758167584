+function () {
    // window.consolestop = false;

    if (window.webFunctions) {

        var createLoader = function(element) {
            var loader = {
                element: $('<div class="o-loading"><div class="o-loading__icon"></div></div>'),
                start: function() {
                    this.element.addClass('o-loading--visible');
                },
                stop: function(delay) {
                    var _delay = delay ? delay : 0;
                    var _this = this;

                    setTimeout(function(){
                        _this.element.removeClass('o-loading--visible');
                    }, _delay);
                }
            };

            var loadingContainer = null;
            if(!element) {
                loadingContainer = $('body')
            }
            else {
                var loadingContainers = element.parents('.o-loading-container');

                if(loadingContainers.length) {
                    loadingContainer = $(loadingContainers[0]);
                }

            }

            if(loadingContainer && loadingContainer.children('.o-loading').length === 0) {
                loadingContainer.append(loader.element)
            }
            else {
                loader.element = $(loadingContainer.children('.o-loading')[0]);
            }

            return loader;
        };

        var reCaptcha = null,   // reCaptcha element
            reCaptchaContainer = document.getElementsByClassName('js-recaptcha-container')[0],  // Parents element which 'set' maximum width for reCaptcha
            reCaptchaWrap = document.getElementsByClassName('js-recaptcha-wrap')[0],    //  Directly parent which has broken width by recaptcha, so we need scale them
            reCapatchaInit = false; // store if we have allready loaded reCaptcha

        var reCaptchaInit = function(){
            // Init reCaptcha
            grecaptcha.render('g-recaptcha', {
                sitekey: '6Le_oTcUAAAAADpF6g3rdsEbZTPbuqLGfqOC0mdb'
            });

            reCaptcha = document.getElementById('g-recaptcha'); // store element
            reCaptchaResponsive();  // run responsive fix
            reCapatchaInit = true;
        };

        var reCaptchaResponsiveReset = false;   // Check if we need reset scaling
        var reCaptchaResponsive = function() {
            var reCaptchaContainerWidth = reCaptchaContainer.clientWidth,
                reCaptchaWidth = reCaptcha.clientWidth;

            if(reCaptchaContainerWidth < reCaptchaWidth){
                var scale = reCaptchaContainerWidth / reCaptchaWidth;

                reCaptchaWrap.style.webkitTransform = 'scale(' + scale + ')';
                reCaptchaWrap.style.MozTransform = 'scale(' + scale + ')';
                reCaptchaWrap.style.msTransform = 'scale(' + scale + ')';
                reCaptchaWrap.style.OTransform = 'scale(' + scale + ')';
                reCaptchaWrap.style.transform = 'scale(' + scale + ')';

                reCaptchaResponsiveReset = false;
            }
            else if(!reCaptchaResponsiveReset) {
                reCaptchaWrap.style.webkitTransform = 'none';
                reCaptchaWrap.style.MozTransform = 'none';
                reCaptchaWrap.style.msTransform = 'none';
                reCaptchaWrap.style.OTransform = 'none';
                reCaptchaWrap.style.transform = 'none';

                reCaptchaResponsiveReset = true;
            }
        };

        window.addEventListener('resize', function() {
            if(reCapatchaInit){
                reCaptchaResponsive();
            }
        });

        var jsCookies = {
            // this gets a cookie and returns the cookies value, if no cookies it returns blank ""
            get: function(c_name) {
                if (document.cookie.length > 0) {
                    var c_start = document.cookie.indexOf(c_name + "=");
                    if (c_start !== -1) {
                        c_start = c_start + c_name.length + 1;
                        var c_end = document.cookie.indexOf(";", c_start);
                        if (c_end === -1) {
                            c_end = document.cookie.length;
                        }
                        return decodeURI(document.cookie.substring(c_start, c_end));
                    }
                }
                return "";
            },

            // this sets a cookie with your given ("cookie name", "cookie value", "good for x days")
            set: function(c_name, value, expiredays) {
                var exdate = new Date();
                exdate.setDate(exdate.getDate() + expiredays);
                document.cookie = c_name + "=" + encodeURI(value) + ((expiredays === null) ? "" : "; expires=" + exdate.toUTCString()) + "; path=/";
            },

            // this checks to see if a cookie exists, then returns true or false
            check: function(c_name) {
                c_name = jsCookies.get(c_name);
                return c_name !== null && c_name !== "";
            }
        };

        window.webFunctions.createLoader = createLoader;
        window.webFunctions.jsCookies = jsCookies;
        window.reCaptchaInit = reCaptchaInit;
    }

}();
