+function () {
    // window.consolestop = false;

    $(document).ready(function () {
        var timerResetForm = null;

        var formValidation = function() {
            var form = $('form.js-form-validation');

            if (form.length) {
                var notificationsBox = $('div#o-notifications');
                var redirectUrl = form.attr('data-redirect-url');
                // Check if we have specific redirect URL after valid or use default action
                redirectUrl = redirectUrl ? redirectUrl : form.attr('action');

                form.submit(function (e) {
                    var data = {};

                    // Create loading HTML
                    var loading = window.webFunctions.createLoader(form);
                    loading.start();    // run loading

                    // Find every fields in form
                    $(this).find('input, select, textarea').each(function(i){
                        data[$(this).attr('name')] = $(this).val();
                    });

                    // Remove all status classes for each field
                    for (var key in data) {
                        form.find('[name=' + key + ']').parents('.o-form__label, .o-tooltip').removeClass('o-form__label--invalid o-tooltip--visible');
                    }

                    // Hide every notifications flash box messages
                    notificationsBox.addClass('o-notifications--hidden');
                    $('div#o-notifications div.o-notifications__item').each(function (i, el) {
                        $(el).addClass('u-hidden');
                    });

                    var test = $(this).attr('action');

                    setTimeout(function(){
                        // Make request
                        $.ajax({
                            type: 'POST',
                            url: test,
                            data: data,
                            dataType: 'json',
                            success: function (data) {
                                // If is something wrong with validation data
                                if (data.status === 0) {
                                    loading.stop(100); // stop loading

                                    // Show tooltip with error for specific input
                                    if (data.hasOwnProperty('errors')) {
                                        for (var key in data.errors) {
                                            form.find('[name=' + key + ']').parents('.o-form__label, .o-tooltip').addClass('o-form__label--invalid o-tooltip--visible');
                                        }
                                    }

                                    resetGreCaptcha();
                                }
                                // Everything is ok, do redirect
                                else if(data.status === 3) {
                                    window.location.replace(redirectUrl);
                                }

                                // If is everything ok (=== 1) or something wrong with sending email (=== 2) show notificationsBox
                                if(data.status === 1 || data.status === 2) {
                                    loading.stop(100); // stop loading

                                    // Show notification for specific input
                                    if (data.hasOwnProperty('notifications')) {
                                        // Remove hidden for specific notification item message
                                        for (var key in data.notifications) {
                                            $('div#o-notifications__' + key).removeClass('u-hidden');
                                        }

                                        // Remove hidden from notif. wrapper
                                        notificationsBox.removeClass('o-notifications--hidden');
                                    }

                                    if(data.status === 1){
                                        timerResetForm = setTimeout(function() {
                                            resetForm(form, notificationsBox);
                                        }, 3000);
                                    }
                                }


                            },
                            error: function (data) {
                                loading.stop(100); // stop loading

                                // Remove hidden for specific notification item message
                                $('div#o-notifications__error').removeClass('u-hidden');

                                // Remove hidden from notif. wrapper
                                notificationsBox.removeClass('o-notifications--hidden');

                                timerResetForm = setTimeout(function() {
                                    resetForm(form, notificationsBox);
                                }, 15000);
                            }
                        });
                    }, 333);

                    e.preventDefault();
                });
            }
        };

        // Find form for validation
        formValidation();

        var resetForm = function(form, notificationsBox){
            $(form)[0].reset();
            window.webFunctions.checkInputValue();
            resetGreCaptcha();

            $(notificationsBox).addClass('o-notifications--hidden');
            $(notificationsBox).find('.o-notifications__item').addClass('u-hidden');
        };

        var resetGreCaptcha = function(){
            if(typeof grecaptcha !== 'undefined') {
                grecaptcha.reset();
            }
        };

        $('.js-reset-form').bind('click', function(){
            if(timerResetForm) {
                clearTimeout(timerResetForm);
            }
           resetForm('form.js-form-validation', 'div#o-notifications');
        });
    });
}();
