+function () {
    // window.consolestop = false;

    $(document).ready(function () {

        var checkInputValue = function () {
            var checkInputValueLabel = $('.js-check-input-value-label');
            if (checkInputValueLabel.length) {
                $('.js-form-input', checkInputValueLabel).each(function (i, el) {
                    checkValue($(el));
                });
            }
        };

        checkInputValue();

        window.webFunctions.checkInputValue = checkInputValue;

        $(document).on('focus', '.js-form-input', function () {
            $(this).parents('.o-form__label').addClass('o-form__label--focused');
        });

        $(document).on('blur', '.js-form-input', function () {
            checkValue($(this));
            $(this).parents('.o-form__label').removeClass('o-form__label--focused');
        });
    });

    var checkValue = function (el) {
        el.removeClass('not-empty');

        if (jQuery.trim(el.val()) !== '') {
            el.addClass('not-empty');
        }
        else {
            el.val(null);
        }
    }
}();
