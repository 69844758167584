+function () {
    if (!window.webFunctions.jsCookies.check('alertCookies')) {
        var jsCookies = document.getElementsByClassName('js-cookies')[0];
        var jsCookiesButton = document.getElementsByClassName('js-cookies__button')[0];
        var bodyPaddingBottom = document.body.style.paddingBottom;
        var lastBodyPaddingBottom = null;

        var calculateBodyPaddingBottom = function() {
            if(lastBodyPaddingBottom !== jsCookies.clientHeight) {
                document.body.style.paddingBottom = jsCookies.clientHeight + 'px';
                lastBodyPaddingBottom = jsCookies.clientHeight;
            }
        };

        var removeJSCookies = function(){
            window.webFunctions.jsCookies.set('alertCookies', true, 365);
            jsCookies.classList.add('o-cookies--hidden');
            document.body.style.paddingBottom = bodyPaddingBottom;

            jsCookiesButton.removeEventListener('click', removeJSCookies);
            window.removeEventListener('resize', calculateBodyPaddingBottom);
        };

        jsCookies.classList.remove('o-cookies--hidden');
        calculateBodyPaddingBottom();

        jsCookiesButton.addEventListener('click', removeJSCookies);
        window.addEventListener('resize', calculateBodyPaddingBottom);
    }
}();
